*:root {
  --primary: #fefefe;
  --secondary: #232224;
  --tertiary: #ca69f9;
  --accent1: #423d47;
  --accent2: #efecf0;
  --black: #000000;
  --line-p: #aeaaaf;
  --box-shadow: 10px 8px 24px rgba(149, 157, 165, 0.2);
}
/* login form style begin */
.login-form-wrapper {
  border: 1px solid var(--accent1);
  box-shadow: 0px 2px 2px 2px rgba(100, 137, 174, 0.2);
  margin: 2rem 1rem 2rem;
  border-radius: 4px;
  text-align: center;
  padding: 1rem 1rem;
}
.form__header {
  padding-top: 1.5rem;
  margin-bottom: 1rem;
}
.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.input__wrapper {
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  justify-content: flex-start;
  width: 100%;
}
.form__input,
.form__label {
  box-sizing: border-box;

  font-weight: 600;
  font-size: 0.875rem;
}
.form__input {
  border-radius: 4px;
  width: 100%;
  border: 1px solid var(--tertiary);
  margin-bottom: 1rem;
  height: 3rem;
  padding-left: 1rem;
}
.form__input:focus {
  outline: none;
  border: 1px solid var(--accent1);
}
.form__label {
  margin-bottom: 0.125rem;
}
.form-btn-wrapper {
  width: 100%;
  margin: 0.5rem 0;
  gap: 0.5rem;
  display: flex;
  flex-direction: column;
}
.form__loginLink {
  color: var(--tertiary);
  text-decoration: underline;
  margin-left: 0.25rem;
}
.form__text {
  margin: 0.25rem 0 0.75rem;
}
@media (min-width: 768px) {
  /* Login/signup style */
  .login-form-wrapper {
    margin: 2rem auto;
    width: 30rem;
  }
}

@media (min-width: 1080px) {
  /* Login/signup style */
  .login-form-wrapper {
    margin: 4rem auto;
  }
}

@media (min-width: 1440px) {
  /* Login/signup style */
  .login-form-wrapper {
  }
}
