*:root {
  --primary: #fefefe;
  --secondary: #232224;
  --tertiary: #ca69f9;
  --accent1: #423d47;
  --accent2: #efecf0;
  --black: #000000;
  --line-p: #aeaaaf;
  --box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  --margin-large: 70rem;
}

.hs-container {
  padding: 2rem 0 0;
  position: relative;
}
.hs-hook-wrapper {
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
  position: relative;
  margin-right: 1rem;
}
.hs-shape-wrapper {
  display: none;
}
.hs__shape {
  z-index: -1;
}
.hs__header {
  margin-bottom: 1rem;
}

.hs-text-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.hs__sub-header {
  color: var(--tertiary);
  margin-bottom: 1rem;
}
.hs__text {
  margin-bottom: 0.5rem;
  font-size: 0.75rem;
}

.hs-columns-wrapper {
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: 2.5rem;
}
.hs-column-wrapper {
  margin: 3rem 1rem 0;
}
.hs__column-header {
  text-align: center;
  margin-bottom: 1rem;
}
.hs__column-text {
  font-size: 0.75rem;
}
.hs-train-container {
  background: var(--accent2);
  padding: 3rem 1rem 8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.hs-train-header-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 0.5rem;
}
.hs-train-text-wrapper {
  padding-bottom: 1rem;
}
.hs__train-header {
  color: var(--tertiary);
}
.hs__train-sub-header {
  font-size: 1.125rem;
  color: var(--black);
}
.hs__train-img-wrapper {
  width: 100%;
  height: 15rem;
  text-align: center;
}
.hs__train-img {
  object-fit: cover;
  height: 100%;
  border-radius: 50%;
  border: 0.75px solid var(--accent1);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 10px;
}
.hs-train-card-wrapper {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  row-gap: 1rem;
  justify-items: center;
  margin-top: 3rem;
}

.hs__train-card {
  width: 100%;
  border-radius: 4px;
  height: 16rem;
}
.train-card-text-wrapper {
  color: var(--primary);
  height: 100%;
  padding: 0 2.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.hs__card-header {
  margin-bottom: 0.5rem;
}
.hs__card-icon,
.hs__card-text {
  color: var(--primary);
}
.hs__card-icon {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

/* Homescreen style begin */

/* Homescreen style end */
@media screen and (min-width: 768px) {
  /* Homescreen style  */
  .hs-hook-wrapper {
    margin-left: 4rem;
    margin-right: 4rem;
  }
  .hs-text-wrapper {
    width: 45%;
  }
  .hs__train-text {
    width: 100%;
  }
  .hs-columns-wrapper {
    margin-left: 4rem;
    margin-right: 4rem;
  }
  .hs-columns-wrapper {
    flex-direction: row;
  }
  .hs__column-header {
    text-align: left;
  }
  .hs__column-text {
    width: 80%;
  }
  .hs-train-container {
    padding: 3rem 4rem 4rem;
    position: relative;
  }
  .hs-train-text-img-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .hs__train-img-wrapper {
  }
  .hs__train-img {
    height: 14rem;
  }
  .hs-train-card-wrapper {
    grid-template-columns: repeat(3, 1fr);
    column-gap: 1rem;
  }
  .hs__train-card {
    height: 24rem;
    display: flex;
    align-items: center;
  }
  .train-card-text-wrapper {
    padding: 0 1.5rem;
    justify-content: flex-start;
    height: 18rem;
  }
  .hs__card-header {
    text-align: center;
  }
}
@media screen and (min-width: 1080px) {
  /* Homescreen style  */
  .hs-shape-wrapper {
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    height: 28.5rem;
    width: 40rem;
  }
  .hs__shape {
    position: relative;
    height: 100%;
    width: 100%;
  }
  .hs-shape-img-wrapper {
    position: absolute;
    top: 4rem;
    right: 7rem;
    height: 21rem;
    width: 21rem;
  }
  .hs__shape-img {
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
    width: 100%;
  }
  .hs-hook-wrapper {
    height: 18rem;
    margin-top: 7rem;
  }
  .hs-hook-wrapper,
  .hs-columns-wrapper {
    margin-left: 10rem;
    margin-right: 10rem;
  }

  .hs-columns-wrapper {
    margin-bottom: 7.5rem;
    margin-top: 4.5rem;
  }
  .hs-column-wrapper {
    margin: 3rem 3rem 0 0;
  }
  .hs__column-text {
    font-size: 0.875rem;
  }
  .hs__text {
    margin-bottom: 1rem;
    font-size: 1rem;
  }
  .hs__train-text {
    width: 90%;
  }
  .hs-train-container {
    padding: 3rem 10rem 6rem;
    position: relative;
  }
  .hs-train-text-wrapper {
    padding-left: 4rem;
  }
  .hs__train-header {
    font-size: 2.5rem;
  }
  .hs__train-text {
    font-size: 0.875rem;
  }
  .hs-train-card-wrapper {
    margin-top: 4rem;
    column-gap: 2rem;
    justify-content: space-between;
  }
  .train-card-text-wrapper {
    padding: 0 1.5rem;
    justify-content: flex-start;
    height: 15.5rem;
  }
  .hs__train-card {
    width: 16rem;
    height: 22rem;
  }
  .hs__train-img-wrapper {
    height: 21rem;
  }
  .hs__train-img {
    height: 21rem;
  }
}
@media screen and (min-width: 1440px) {
  /* Homescreen style  */
  .hs__header {
    font-size: 2.5rem;
  }
  .hs__sub-header {
    font-size: 1.5rem;
  }

  .hs-shape-wrapper {
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    height: 28.5rem;
    width: 50vw;
  }
  .hs__shape {
    position: relative;
    height: 100%;
    display: none;
    width: 100%;
  }
  .hs-shape-img-wrapper {
    position: absolute;
    top: 4rem;
    right: 14rem;
    height: 21rem;
    width: 21rem;
  }
  .hs__shape-img {
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
    width: 100%;
  }
  .hs-hook-wrapper {
    height: 18rem;
    margin-top: 7rem;
    width: var(--margin-large);
    margin: 7rem auto;
  }
  .hs-columns-wrapper {
    width: var(--margin-large);
    margin: 7rem auto;
  }
  .hs-column-wrapper {
    margin: 3rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .hs__column-header {
    text-align: center;
  }
  .hs__column-text {
    font-size: 1rem;
  }
  .hs-train-container {
    padding: 3rem 16rem 6rem;
  }
  .hs-train-text-img-wrapper {
    width: var(--margin-large);
  }
  .hs__train-header {
    font-size: 2.5rem;
  }
  .hs__train-text {
    font-size: 0.875rem;
  }
  .hs-train-card-wrapper {
    margin-top: 4rem;
  }

  .hs__train-img-wrapper {
    height: 21rem;
  }
  .hs__train-img {
    height: 21rem;
  }
}
@media screen and (min-width: 1800px) {
  .hs-shape-img-wrapper {
    right: 20vw;
  }
}
@media screen and (min-width: 2200px) {
  .hs-shape-img-wrapper {
    right: 28vw;
  }
}
