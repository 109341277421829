*:root {
  --primary: #fefefe;
  --secondary: #232224;
  --tertiary: #ca69f9;
  --accent1: #423d47;
  --accent2: #efecf0;
  --black: #000000;
  --line-p: #aeaaaf;
  --box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.header {
  border-bottom: 1px solid var(--line-p);
  justify-content: center;
  padding: 1rem 0;
}
.nav-links-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  display: none;
}
.nav__ul {
  display: flex;
}
.nav__ul li,
.nav__ul button {
  margin: 0 1rem;
  cursor: pointer;
}
.logo-wrapper {
  max-height: 7rem;
  text-align: center;
  display: flex;
  justify-content: center;
}
.home-link {
  width: 4em;
}

.homepage-logo {
  max-height: 5rem;
}

.logo-small {
  height: 100%;
  max-height: 4rem;
}
.hamburger-wrapper {
  position: absolute;
  left: 1.5rem;
  top: 2.5rem;
  z-index: 1002;
}
.hamburger-wrapper:hover {
  cursor: pointer;
}
.hamburger__line {
  background: var(--black);
  width: 1.5rem;
  height: 0.2rem;
  margin: 0.25rem 0;
  border-radius: 100vmax;
}
.hamburger__lineOpen {
  background: var(--primary);
  transition: 125ms ease-in;
}
.hamburger__open {
  transform: rotate(90deg);
  transition: 250ms ease-in;
}

.hamburger__close {
  transform: rotate(0);
  transition: 250ms ease-in;
}

.hamburger-links-wrapper {
  background: var(--accent1);
  color: var(--primary);
  position: fixed;
  top: 0;
  height: 100vh;
  z-index: 1001;
  width: 50%;
  animation-name: hamburger;
  animation-duration: 250ms;
  animation-timing-function: ease-out;
}

@keyframes hamburger {
  0% {
    width: 0;
  }
  100% {
    width: 50%;
    color: var(--accent2);
  }
}

.hamNav__list,
.hamNav__list2 {
  list-style: none;
  display: flex;
  flex-direction: column;
  padding: 0;
}
.hamNav__list {
  margin-top: 10rem;
}
.hamNav__list2 {
  margin-top: 2rem;
}
.hamNav__li {
  padding: 0.5rem 0 0.5rem 1rem;
  color: var(--primary);
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: flex-start;
}
.hamNav__a {
  color: var(--primary) G;
}
.hamNav__footer {
  background-color: var(--accent1);
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 10rem;
  text-align: center;
}
.hamNav__li:hover {
  background: var(--accent2);
  color: var(--black);
  transition: 120ms ease-in-out;
}
.selected__nav {
  color: var(--tertiary);
}
/* Header style end */
@media (min-width: 1080px) {
  .header {
    display: flex;
    padding: 1rem 10rem;
  }
  .nav {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .nav-links-wrapper {
    display: flex;
  }
  .hamburger-wrapper {
    display: none;
  }
  .hamburger-links-wrapper {
    display: none;
  }
}
@media (min-width: 1440px) {
  .header {
  }
  .nav {
    width: 80rem;
  }
}
